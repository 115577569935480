html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  pointer-events: none;
}

.fc .fc-daygrid-day-top {
  cursor: pointer;
}

.btn--main:focus {
  background-color: #07c3c3;
  border: 1px solid #07c3c3;
  color: white;
}

.btn--gray:focus {
  background-color: gray;
  border: 1px solid gray;
  color: white;
}

.btn.disabled {
  background: #797979 !important;
  border-color: #797979 !important;
  color: white !important;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-check-input:checked {
  background-color: #079d9a !important;
  border-color: #079d9a !important;
}

.form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
}
