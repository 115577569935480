footer {
  background-color: #f2f0f0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  z-index: 999;
  .menu {
    background-color: #f2f0f0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
    li {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  a {
    display: flex;
    font-size: 0.6875rem;
    height: 61px;
    color: #7c797a;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.active {
      color: #f06a0f !important;
      font-weight: bold;
      .icon-home {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19.514' viewBox='0 0 19 19.514'%3E%3Cpath id='Icon_metro-home' data-name='Icon metro-home' d='M25.821,17.336v10.53H19.849v-7.02H12.792v7.02H6.821V17.336l9.5-8.983Z' transform='translate(-6.821 -8.353)' fill='%23f06a0f'/%3E%3C/svg%3E%0A");
      }
      .icon-group {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='21' viewBox='0 0 33 21'%3E%3Cpath id='Icon_material-group' data-name='Icon material-group' d='M24,16.5A4.5,4.5,0,1,0,19.5,12,4.481,4.481,0,0,0,24,16.5Zm-12,0A4.5,4.5,0,1,0,7.5,12,4.481,4.481,0,0,0,12,16.5Zm0,3c-3.495,0-10.5,1.755-10.5,5.25V28.5h21V24.75C22.5,21.255,15.495,19.5,12,19.5Zm12,0c-.435,0-.93.03-1.455.075A6.33,6.33,0,0,1,25.5,24.75V28.5h9V24.75C34.5,21.255,27.495,19.5,24,19.5Z' transform='translate(-1.5 -7.5)' fill='%23f06a0f'/%3E%3C/svg%3E%0A");
      }
      .icon-host {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='25' height='22.805' viewBox='0 0 25 22.805'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Cpath id='Path_356' data-name='Path 356' d='M0-23.467H25v-22.8H0Z' transform='translate(0 46.272)' fill='%23f06a0f'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_3265' data-name='Group 3265' clip-path='url(%23clip-path)'%3E%3Cg id='Group_3261' data-name='Group 3261' transform='translate(0 0.28)'%3E%3Cpath id='Path_352' data-name='Path 352' d='M-19.832-16.532-24.582-32.6l-6.547,6.007.033.116-5.981,1.716.208.725-1.222.35a1.4,1.4,0,0,0-.958,1.728l1.315,4.588a1.4,1.4,0,0,0,1.728.958l1.222-.35.218.759,1.1-.315,1.5,5.231a1.4,1.4,0,0,0,1.728.958l.7-.2a1.4,1.4,0,0,0,.958-1.728l-1.5-5.231,1.657-.475Z' transform='translate(39.104 32.598)' fill='%23f06a0f'/%3E%3C/g%3E%3Cg id='Group_3262' data-name='Group 3262' transform='translate(18.433 5.699)'%3E%3Cpath id='Path_353' data-name='Path 353' d='M0-1.964,5.715-3.872' transform='translate(0 3.872)' fill='none' stroke='%23ff6903' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3Cg id='Group_3263' data-name='Group 3263' transform='translate(18.993 10.559)'%3E%3Cpath id='Path_354' data-name='Path 354' d='M0,0,5.268,1.147' fill='none' stroke='%23ff6903' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3Cg id='Group_3264' data-name='Group 3264' transform='translate(17.596 0.739)'%3E%3Cpath id='Path_355' data-name='Path 355' d='M0-4.2,3.695-8.287' transform='translate(0 8.287)' fill='none' stroke='%23ff6903' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
      .icon-host-event {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.5' height='22' viewBox='0 0 24.5 22'%3E%3Cpath id='Icon_material-event-note' data-name='Icon material-event-note' d='M23.556,11.4H9.944v2.2H23.556Zm2.722-7.7H24.917V1.5H22.194V3.7H11.306V1.5H8.583V3.7H7.222A2.488,2.488,0,0,0,4.514,5.9L4.5,21.3a2.5,2.5,0,0,0,2.722,2.2H26.278A2.511,2.511,0,0,0,29,21.3V5.9A2.511,2.511,0,0,0,26.278,3.7Zm0,17.6H7.222V9.2H26.278Zm-6.806-5.5H9.944V18h9.528Z' transform='translate(-4.5 -1.5)' fill='%23F06A0F'/%3E%3C/svg%3E%0A");
      }
    }
    &:hover {
      color: #7c797a;
    }
  }
  &.footer-admin {
    .menu {
      li {
        flex: 50%;
        max-width: 50%;
        width: 50%;
        border-left: 1px solid rgba($color: #868384, $alpha: 0.3);
        &:first-child {
          border-left: none;
        }
      }
    }
  }
  .active {
    color: #f06a0f;
    font-weight: bold;
  }
}

footer,
.section-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.section-content {
  padding-bottom: 60px;
  padding-top: 100px;
  overflow: hidden;
  .container {
    padding-bottom: 20px;
    display: flex;
    height: 100%;
  }

  &--top {
    .container {
      position: relative;

      flex-wrap: wrap;

      > div {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-top {
        align-self: flex-start;
      }

      .row--bot,
      .row-bot {
        align-self: flex-end;

        margin-top: 10px;
      }
      .row-bot {
        &.is-fixed {
          position: fixed;
          bottom: 60px;
          background: #fff;
          left: 0;
          right: 0;
          max-width: 640px;
          margin: 0 auto;
          padding-left: 20px;
          padding-right: 20px;
          z-index: 99;
          border-top: 1px solid #f2f0f0;
          border-bottom: 1px solid #f2f0f0;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    &.is-content-fixed {
      padding-bottom: 300px;
    }
  }

  &--welcome {
    position: relative;

    &:after {
      height: 50%;
      background-color: rgba($color: #868384, $alpha: 0.12);
      bottom: 20px;
      left: 0;
      right: 0;
      content: '';
      position: absolute;
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }

  &--profile {
    padding-top: 92px;

    .c-form.c-form--profile {
      margin-top: -50px;
      margin-bottom: 20px;
    }
  }
}
