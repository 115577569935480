@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.member-detail-page {
  font-family: $font-family-other;
  .c-image {
    img {
      width: 100%;
    }
  }

  .c-form .mygroup {
    display: block;
  }
  .form-group .form-group--select {
    display: block;
  }
  .form-group .form-group--select {
    display: block;
  }
  .btn--solid03 {
    &:hover {
      color: #fff !important;
    }
  }
}
