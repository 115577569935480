@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.list-events-page {
  font-family: $font-family-other;

  .c-txt-14 {
    color: #f06a0f !important;
  }
  .link-share,
  .total-members {
    cursor: pointer;
  }
  .host-an-event {
    cursor: pointer;
  }
  .badge-light-link {
    color: #007bff !important;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #51aaaa !important;
      text-decoration: none !important;
    }
  }
}
