.size-width-desktop-col-2 {
  width: 1100px;
  margin: 0 auto 1rem auto;
}

.size-width-desktop-col-1 {
  width: 800px;
  margin: 0 auto 1rem auto;

  .upload-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .btn.disabled {
    background: #797979 !important;
    border-color: #797979 !important;
    color: white !important;
  }
}

.section-content {
  // max-width: 2000px;
  margin: 0 auto;
}
