@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

.group-page {
  font-family: $font-family-other;
  .text-underline {
    text-decoration: underline !important;
  }

  .link-share {
    font-size: rem(10);
    font-weight: bold;
    color: #7e7e7e;
  }

  .link-org {
    font-weight: bold;
    color: #f06a0f;
  }

  .c-txt-15 {
    font-size: rem(15);
  }
  .c-txt-25 {
    font-size: rem(25);
  }
  a.c-txt-15 {
    text-decoration: underline;
  }
}

.group-list {
  margin-top: 50px;

  &__item {
    margin-bottom: 1rem !important;
  }

  &--mygroup {
    margin-top: 0;
    margin-bottom: 30px;

    h3 {
      font-size: rem(14);
      color: #f06a0f;
    }

    .group-list__item {
      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
      }
    }

    .group-list__info {
      p {
        span {
          font-size: rem(12);

          &.t-member {
            color: #da5039;
          }

          &.t-deal {
            color: #51aaaa;
          }
        }
      }
    }

    .group-list__btn {
      flex: 0 0 40px;
      max-width: 40px;
    }
  }
  .btn--pending {
    color: #8f8c8d !important;
    border-color: #8f8c8d !important;
  }
}
