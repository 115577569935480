@import '../../../assets/css/desktop.scss';

// Style for calendar library start
.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border: none !important;
}
.resize-calendar {
  width: 96%;
  height: fit-content;
  margin-top: 0 !important;
  margin: 0 auto;
  ul {
    line-height: 2;
  }
  i {
    font-size: 12px;
  }
}
.fc {
  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    font-size: 10px;
  }
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0;
  // display: flex; demo
  // flex-wrap: wrap; demo
}
.fc-daygrid-event {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.fc .fc-button-primary {
  background-color: transparent;
  color: black;
  border: none;
}
.fc .fc-button-primary:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgb(241, 241, 241);
  color: black;
}
.fc .fc-button-primary:focus {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none;
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.fc .fc-button-group {
  display: flex;
  flex-direction: column;
}
.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}
.fc-footer-toolbar > .fc-toolbar-chunk:nth-child(3)::before {
  font-family: 'Font Awesome 5 Free';
  color: red;
  position: relative;
  content: '\f06a';
  font-weight: 900;
}
.fc-toolbar-title {
  text-transform: uppercase;
}
.w-fit {
  width: fit-content;
}
.fc-daygrid-block-event {
  width: 6px;
  height: 6px;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk button {
  font-weight: 900;
  color: lightgray;
}
.fc-daygrid-body tbody tr {
  display: flex;
  margin: 0.4rem auto;
  width: 100%;
}
.fc-daygrid-body tbody tr td {
  flex: 1;
}
.fc-col-header {
  width: 100% !important;
  thead {
    tr {
      font-size: 10px;
    }
  }
}
.fc {
  .fc-toolbar.fc-header-toolbar {
    font-size: 10px;
    margin: 8px 0;
  }
}
.fc {
  .fc-toolbar-title {
    font-size: 12px;
    font-weight: 700;
  }
}
.fc-scroller::-webkit-scrollbar {
  display: none;
}
.fc-scrollgrid-sync-table {
  width: 100% !important;
  height: 135px;
  > tbody {
    padding: 0 2px;
  }
}
.fc-daygrid-body-unbalanced {
  width: 100% !important;
}
.calendar__desc-point {
  margin: 10px 5%;
  > div {
    font-size: 10px;
  }
  &-close:before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ff6903;
  }
  &-collection:before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #68a4c4;
  }
}
// Style for calendar library end
.event__close-info {
  padding: 0;
  margin-top: 16px;
  &-day {
    display: flex;
    flex-direction: column;
    float: left;
    text-align: center;
    &-number {
      font-weight: 600;
      color: $gray-600;
    }
    &-text {
      font-size: 0.8rem;
      color: $gray-500;
    }
  }
  &-detail {
    &-name {
      height: 20px;
      position: relative;
      > span {
        width: 90%;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.6rem;
        color: #adb5bd;
      }
    }
    > p {
      font-size: 0.6rem;
      margin: 0;
      color: #ff6903;
      font-weight: 600;
    }
    > span {
      margin-bottom: 0;
      display: block;
      font-size: 0.6rem;
      color: $gray-500;
    }
  }
}

.event__collection-info {
  padding: 0;
  margin-top: 16px;
  &-day {
    display: flex;
    flex-direction: column;
    float: left;
    text-align: center;
    &-number {
      font-weight: 600;
      color: $gray-600;
    }
    &-text {
      font-size: 0.8rem;
      color: $gray-500;
    }
  }
  &-detail {
    &-name {
      height: 20px;
      position: relative;
      > span {
        width: 90%;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.6rem;
        color: #adb5bd;
      }
    }
    > p {
      font-size: 0.6rem;
      margin: 0;
      color: #68a4c4;
      font-weight: 600;
    }
    > span {
      margin-bottom: 0;
      display: block;
      font-size: 0.6rem;
      color: $gray-500;
    }
  }
}
.recommend-event-in-feature {
  padding: 12px;
}

.event-info-see-more {
  float: right;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
