@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

#purchase-detail-page {
  font-family: "Oxygen", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}
.group-list {
  margin-top: 0;
}
.group-buttom {
  width: 100%;
  &__Cancel {
    display: inline-block;
    color: grey;
    font-size: 10px;
    padding: 2px;
    margin: 2px;
    float: right;
  }
  &__Edit {
    display: inline-block;
    color: grey;
    font-size: 10px;
    padding: 2px;
    margin: 2px;
    float: right;
  }
}
.group-list__detail {
  .text-blue {
    color: #51aaaa;
    font-size: 0.9375rem !important;
  }
}
.list-item {
  display: block;
  font-size: 17px;
  font-weight: bold;
  margin: 16px 0px;
  color: rgb(240, 106, 15);
}
// .mb-4{
//  border: 1px solid #ddd;
// }
.c-form .upload-image {
  /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%); */
  // width: 107px;
  height: 107px;

  width: 100%;
  /* border-radius: 50%; */
  // border: 0.5px solid black ;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-form .upload-image.upload-screenshot {
  width: 107px;
  -webkit-box-shadow: none;
  box-shadow: none;
  // border: 1px solid #ddd;
  height: 107px;
  // display: inline-block;
  border-radius: 0;
  text-align: center;
}

.list-check-box-container {
  .custom-checkbox input[type="checkbox"] + label:before {
    border: 1px solid #51aaaa;
  }
}
