@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.list-members-page {
  font-family: $font-family-other;
  .link-share,
  .total-members {
    cursor: pointer;
  }
  .icon-edit-image {
    display: block;
  }
  .search {
    width: 100%;
    text-align: center;
    &-input {
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      width: 80%;
      padding-left: 8px;
    }
  }
  .event-hosted {
    color: #f06a0f !important;
    cursor: pointer;
  }
}
.approve-modal {
  font-family: $font-family-other;
  .modal-content {
    padding: 0 !important;
  }
  .welcome-group {
    margin-top: 0;
    input {
      &:focus {
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
      }
    }
  }
  .container {
    padding: 20px !important;
  }
}
