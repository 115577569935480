@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.search-page {
  font-family: $font-family-other;
  .tempItem {
    a {
      color: inherit;
    }
  }
  .disabled {
    pointer-events: none;
  }
}
.search-modal {
  font-family: $font-family-other;
  .modal-content {
    padding: 0 !important;
  }
  .welcome-group {
    margin-top: 0;
    input {
      &:focus {
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
      }
    }
  }
  .passcode {
    font-size: 12px;
    color: gray;
  }
}
