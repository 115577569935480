.dashboard__desktop {
  // width: 1000px;
  margin: auto;

  &-head {
    // margin: 20px -4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      margin-bottom: 0;
      color: #ff6903;
    }
    &-sync {
      display: flex;
      &-date {
        display: flex;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
        border-radius: 4px;
        font-size: 0.8rem;
        &-start {
          margin-right: 6px;
        }
        &-end {
          margin-left: 6px;
        }
      }
      &-week {
        margin-left: 12px;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
        .form-select {
          border: none;
          color: #a9a7a7;
          background-color: #ffffff;
          font-size: 0.8rem;
        }
      }
    }
  }

  .summary {
    .border-custom {
      border: 1px solid #15cdca;
      font-weight: 600;

      span {
        color: #414141;
      }
      .text-show {
        color: #ff6903;
      }
    }
  }

  .btn-prev,
  .btn-next {
    background-color: #fff;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    color: #ff6903;
    font-weight: 500;
    width: auto;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: #ff6903;
      color: #fff;
    }
  }
}
.chart-layout {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  margin-top: 20px;
  > div {
    padding: 12px;
    border-radius: 16px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
    > p {
      margin-bottom: 8px;
      color: black;
      font-weight: 600;
    }
  }
}
.datePicker {
  &__startDay,
  &__endDay {
    margin-bottom: 24px;
  }
}
.sync-data-btn {
  > button {
    width: 320px;
    margin: 0 auto;
  }
}
.top-members_list {
  margin-bottom: 0;
  li {
    display: flex;
    list-style: none;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #414141;
  }
}
.top-members {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
