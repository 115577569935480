@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.update-group-page {
  font-family: $font-family-other;
  width: 100%;
  .c-form .form-group label {
    font-size: $font-size-sm;
  }
  .form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }
  .form-group {
    .c-txt-blue {
      font-size: $font-size-sm;
    }
  }
  .box-setting {
    .custom-control-label {
      font-size: 0.75rem !important;
    }
  }
  #groupImageImg {
    z-index: 999;
  }
}

.errorMessage {
  color: #ff9900;
}
