@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

.page-login {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  .errorMessage {
    color: #ff9900;
  }

  &--screen {
    min-height: auto !important;
  }

  > div {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .main-login-top {
    justify-content: center;
    align-self: center;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .main-login-bot {
    margin-bottom: 20px;
    margin-top: 30px;
    align-self: end;
    max-width: 100%;
    flex: 0 0 100%;
    align-self: flex-end;
    font-size: 14px;
    color: #7c797a;

    .row {
      margin-left: -6px;
      margin-right: -6px;
      margin-top: 10px;
      margin-bottom: 20px;

      .col-6 {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }

  .main-logo {
    text-align: center;
    margin: 20px 0 20px 0;
  }

  .main-img {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 10px;
    text-align: center;
    position: relative;
    .main-link {
      position: absolute;
      top: -30px;
      right: 10px;
      width: 45%;
      background: #fff;
      padding: 10px;
      font-size: 13px;
      border-radius: 10px;
      @include media-breakpoint-down(md) {
        top: -71px;
      }
      &::after {
        content: '';
        width: 45px;
        height: 45px;
        position: absolute;
        top: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        left: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96.156' height='67.216' viewBox='0 0 96.156 67.216'%3E%3Cpath id='Path_298' data-name='Path 298' d='M772.979-1644.914,743.105-1577.7l96.156-67.216H772.979Z' transform='translate(-743.105 1644.914)' fill='%23fff'/%3E%3C/svg%3E%0A");
      }
      a {
        color: #7c797a;
        b {
          color: #ff6903;
          font-weight: bold;
        }
      }
    }
  }
  .main-content {
    input:focus {
      box-shadow: none !important;
    }
  }
  @include media-breakpoint-up(md) {
    .page-login {
      max-width: 640px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(sm) {
    background: white;

    .page-login {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.c-form {
  .upload-image {
    box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
    width: 107px;
    height: 107px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      // border-radius: 50%;
      // position: absolute;
    }

    &-txt {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      color: #7e7e7e;
      flex-direction: column;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      span {
        display: block;
        margin-top: 10px;
      }
    }

    &--square {
      box-shadow: none;
      width: 74px;
      height: 74px;
      background: rgb(246 178 12 / 0.15);
      border-radius: 0;
      padding: 10px;

      .u-file-upload__item {
        color: #f06a0f;
        font-size: 10px;
      }
    }
    &--modal {
      box-shadow: none;
      width: 143px;
      height: 81px;
      background: rgb(246 178 12 / 0.15);
      border-radius: 0;
      background: #fff;
      border: 1px solid #868384;
    }
  }

  .u-file-upload__item .u-file-upload__wrap input {
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-left: 100px;
    margin-right: -100px;
    cursor: pointer;
  }

  .u-file-upload__item input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: transparent;
    color: transparent;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    opacity: 0;
    cursor: pointer;
    width: 100%;
  }

  .upload-name {
    text-align: center;
    font-size: rem(20);
    font-weight: bold;
    color: #f06a0f;
  }

  &--login {
    margin: 20px 0;

    .form-group {
      position: relative;

      & ~ .form-group {
      }
    }

    .c-link-text {
      font-size: rem(12);
      color: #7c797a;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &--signup {
    margin-top: 40px;

    .custom-checkbox {
      margin-top: 40px;
    }

    .btn {
      margin-top: 8px;
      margin-bottom: 8px;
      position: relative;

      i {
        position: absolute;
        position: absolute;
        left: 23%;
      }
    }
  }

  &--email,
  &--search,
  &--profile {
    margin-top: 50px;

    .form-group {
      margin-top: 20px;

      .form-control {
        border-radius: 30px;
        border: none;
        box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
        font-size: rem(12);
        height: 40px;
      }
    }
  }

  &--group {
    .form-group {
      label {
        color: #f06a0f;
        font-size: rem(14);
      }

      & ~ .c-txt-13 {
        margin-top: 25px;
      }
    }
  }

  &--org {
    background: #fef4da;
    padding: 10px;
  }

  &--search {
    .form-group {
      position: relative;
      margin-top: 0;

      .icon-search {
        top: 13px;
        left: 10px;
        position: absolute;
      }

      .form-control {
        padding-left: 30px;
      }
    }
  }

  .slider-toogle {
    background-color: rgba($color: #7c797a, $alpha: 0.1);
    border-radius: 15px;
    padding: 20px;
    font-size: rem(13);
    margin-bottom: 20px;

    .switch {
      position: relative;
      display: inline-block;
      width: 45px;
      height: 21px;
      margin: 0;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #868384;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 17px;
      width: 17px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #15cdca;
    }

    input:focus + .slider {
      // box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.box-pin {
  display: flex;
  margin-top: 12px;

  &__col:not(.password) {
    width: 14.285%;
    flex: 0 0 14.285%;
    padding: 0 4px;

    .form-control {
      padding: 1px;
      height: 68px;
      border-radius: 10px;
      border: 1px solid #868384;
      text-align: center;
    }
  }

  &--first {
    margin-bottom: 40px;

    .form-control {
      padding: 1px;
      height: 68px;
      border: 1px solid #f6b20c;
      text-align: center;
    }
  }
}

.page-login {
  background-color: #15cdca;

  @include media-breakpoint-up(md) {
    .page-login {
      max-width: 640px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(sm) {
    background: white;

    .page-login {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.p-login {
  .text-info-app {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 22px;
    color: #112a46;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .info-number {
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(sm) {
    .text-info-app {
      top: 75px;
      font-size: 0.75rem;
    }
  }

  // Styles cho tablet (medium devices)
  @include media-breakpoint-between(md, lg) {
    .text-info-app {
      top: 85px;
      left: 80px;
      font-size: 0.875rem;
    }
  }
}
