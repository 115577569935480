@import "../../assets/css/base/functions";
@import "../../assets/css/base/variables";
@import "../../assets/css/base/mixins";

.btn ~ .btn {
  margin-top: 0;
}

.text-purple {
  color: purple;
}

.bg-theme-primary {
  background-color: $colormain !important;
}

.manage-delivery-booking {
  font-size: 14px;

  .col-auto {
    flex: 1;
  }

  .btn-export {
    border-color: $orange;
    color: $orange;
    width: 140px;
    border-radius: 999px !important;

    &:hover {
      background-color: $orange;
      color: white;
    }
  }

  .btn-date,
  .btn-prev,
  .btn-next {
    background-color: #fff;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    color: $orange;
    font-weight: 500;
    width: auto;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: $orange;
      color: #fff;
    }
  }
}

.btn-cancel-calendar {
  outline: none;
  border: none;
  font-size: 0.625rem;
}

.rdrDays {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: $colormain !important;
  }

  .rdrDayHovered {
    border-color: $colormain !important;
  }
}

.form-select:focus {
  box-shadow: none;
  border-color: $orange;
}

.rdrNextButton {
  margin-right: 0;
}

.rdrPprevButton {
  margin-left: 0;
}

.rdrMonth {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.css-b62m3t-container {
  min-width: 400px;
}

.css-1s2u09g-control {
  border-radius: 0 !important;
}

.css-14el2xx-placeholder {
  color: $orange !important;
}
