@import "../../assets/css/base/functions";
@import "../../assets/css/base/variables";
@import "../../assets/css/base/mixins" ;

.header {
  position: fixed;
  background: $colormain;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-family: $font-family-sans-serif;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 64px;
  }
}

.container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @include media-breakpoint-up(md) {
    max-width: 640px;
  }
}

.main-logo {
  a {
    span {
      display: block;
      font-size: rem(11);
      color: #fff;
      font-weight: bold;
    }
  }
}

.avatar-top {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;

  &__email {
    color: white;
    font-size: rem(14);
    font-weight: bold;
    padding-right: 10px;
  }

  &__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
    overflow: hidden;
    background: #fff;

    img {
      @include avatarSize
    }
  }
}

.nav-menu {
  background: #f2f0f0;
  text-align: center;
  position: relative;

  a {
    font-size: 0.75rem;
    color: #7e7e7e;
    display: block;
    padding: 5px;
    position: relative;

    &:hover {
      text-decoration: none;
      color: #f06a0f;

      &:after {
        background: #f06a0f;
      }
    }

    &:after {
      position: absolute;
      bottom: 0px;
      content: "";
      height: 2px;
      left: 0;
      right: 0;
      background: none;
    }

    &.active {
      color: #f06a0f;
      font-weight: bold;

      &:after {
        background: #f06a0f;
      }
    }
  }
}
header,
footer,
.section-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.section-content {
  padding-bottom: 60px;
  padding-top: 100px;
  overflow: hidden;
  .container {
    padding-bottom: 20px;
    display: flex;
    height: 100%;
  }

  &--top {
    .container {
      position: relative;

      flex-wrap: wrap;

      > div {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-top {
        align-self: flex-start;
      }

      .row--bot,
      .row-bot {
        align-self: flex-end;

        margin-top: 10px;
      }
      .row-bot {
        &.is-fixed {
          position: fixed;
          bottom: 60px;
          background: #fff;
          left: 0;
          right: 0;
          max-width: 640px;
          margin: 0 auto;
          padding-left: 20px;
          padding-right: 20px;
          z-index: 99;
          border-top: 1px solid #f2f0f0;
          border-bottom: 1px solid #f2f0f0;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    &.is-content-fixed {
      padding-bottom: 300px;
    }
  }

  &--welcome {
    position: relative;

    &:after {
      height: 50%;
      background-color: rgba($color: #868384, $alpha: 0.12);
      bottom: 20px;
      left: 0;
      right: 0;
      content: "";
      position: absolute;
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }

  &--profile {
    padding-top: 92px;

    .c-form.c-form--profile {
      margin-top: -50px;
      margin-bottom: 20px;
    }
  }
}
