.scroll-ui {
    overflow: hidden;
    max-width: 100%;
}

[data-simplebar] {
    position: relative;
    z-index: 0;
    overflow: hidden!important;
    // max-height: inherit;
    -webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

[data-simplebar="init"] {
    display: flex;
}

[data-simplebar] .simplebar-scroll-content,
[data-simplebar] .simplebar-content {
    overflow: hidden;
}

[data-simplebar="init"] .simplebar-scroll-content,
[data-simplebar="init"] .simplebar-content {
    overflow: scroll;
}

.simplebar-scroll-content {
    overflow-x: hidden!important;
    min-width: 100%!important;
    max-height: inherit!important;
    box-sizing: content-box!important;
    &::-webkit-scrollbar {
        background-color: #fff; 
    }
}

.simplebar-content {
    overflow-y: hidden!important;
    box-sizing: border-box!important;
    min-height: 100%!important;
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 11px;
    pointer-events: none;
}

.simplebar-scrollbar {
    position: absolute;
    right: 2px;
    width: 5px;
    min-height: 10px;
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: rgba($black, 0.8);
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.simplebar-track .simplebar-scrollbar.visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 0.5;
    transition: opacity 0 linear;
}

.simplebar-track.vertical {
    top: 0;
}

.simplebar-track.vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
}

.simplebar-track.horizontal {
    left: 0;
    width: auto;
    height: 11px;
}

.simplebar-track.horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
}

.horizontal.simplebar-track .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 5px;
    min-height: 0;
    min-width: 10px;
    width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track {
    right: auto;
    left: 0;
}

[data-simplebar-direction="rtl"] .simplebar-track.horizontal {
    right: 0;
}