$primary-color: #00bfa5;
$hover-color: #00a589;
$background-color: #f8f9fa;
$border-color: #ced4da;
$disabled-color: #c8c8c8;
$orange-color: #f06a0f;

.pagination-buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .pagination-button {
    padding: 10px 20px;
    background: white;
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid $orange-color;
    color: $orange-color;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover:not(:disabled) {
      background: $orange-color !important;
      color: white;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
      background: $disabled-color !important;
      border: 1px solid $disabled-color !important;
    }

    &:active {
      background: $orange-color !important;
      color: white;
      border: 1px solid $orange-color !important;
      box-shadow: none !important;
    }
  }
}

.modal-password {
  font-size: 16px;
  top: 10% !important;

  input.form-control {
    font-size: 16px;
    color: #7c797a;

    &:focus {
      box-shadow: none;
      border-color: $primary-color;
    }
  }

  .modal-content {
    border-radius: 10px;
  }

  .modal-content {
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .form-control {
    border: 1px solid #e0e0e0;
  }

  .btn-primary {
    &:hover {
      background-color: #008c87 !important;
      border-color: #008c87 !important;
    }
  }

  .text-orange {
    color: #f06a0f;
  }

  button.btn-primary:active {
    box-shadow: none !important;
  }
}
