.info-account-bank {
  > p {
    margin-bottom: 10px;
  }
}

.modal-header-created {
  .modal-title {
    font-size: 24px;
  }
}
