.box-pin {
  display: flex;
  margin-top: 12px;
  padding-left: 50px;
  padding-right: 50px;
  &__col {
    width: 25%;
    flex: 0 0 25%;
    padding: 0 4px;

    .form-control {
      padding: 1px;
      height: 68px;
      border-radius: 10px;
      border: 1px solid #868384;
      text-align: center;
      &:focus {
        border-color: #868384 !important;
      }
    }
  }

  &--first {
    margin-bottom: 40px;

    .form-control {
      padding: 1px;
      height: 68px;
      border: 1px solid #f6b20c;
      text-align: center;
      &:focus {
        border-color: #f6b20c !important;
      }
    }
  }
}
