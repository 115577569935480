@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

.created-confirm-page {
  font-family: $font-family-other;
  width: 100%;
  .detail-list-news {
    font-size: 0.75rem;
    color: #51aaaa;
    margin-top: 15px;
    &__item {
      color: #51aaaa !important;
    }
  }
  input {
    &:focus {
      box-shadow: none !important;
    }
  }
  .nav-amount {
    background: rgba(#21cdca, 0.1);
    padding: 20px;
    margin-left: -15px;
    margin-right: -15px;
    .c-txt-org {
      color: #079d9a;
    }
    .note {
      font-size: rem(10);
      color: #079d9a;
    }
    & ~ .c-form {
      margin-top: 30px;

      textarea {
        height: 79px;
        margin-top: 10px;
        margin-bottom: 20px;
        resize: none;
        overflow: auto;

        &.form-control.form-control--gray.form-control--outline {
          background: none;
          border: 1px solid #ccc;
        }
      }
    }
  }
  .is-close {
    display: none;
  }

  .btn.disabled {
    background: #797979;
    border-color: #797979;
    color: white;
  }
}
