@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.welcome-page {
  font-family: $font-family-other;
  min-height: calc(100vh - 180px);
}

@media screen and (max-width: 320px) {
  .group-list__btn {
    flex: 0 0 90px;
  }
}

.btn-group-buy,
.btn-shop {
  &:hover {
    background-color: #079d9a;
    border: 1px solid #079d9a;
  }
}
