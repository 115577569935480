@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.create-event-page {
  font-family: $font-family-other;

  .upload-excel-group {
    font-size: 12px;  
    display: flex;
    .register-schedule-event{
      flex-basis: 70%;
      color: #d1274d;
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
    }
  }
  .upload-excel {
    flex-basis: 30%;
    font-size: 12px;
    cursor: pointer;
    a {
      color: rgb(23, 162, 184);
    }
  }
  .form-group {
    .form-control {
      &:focus {
        box-shadow: none !important;
        border: 1px solid #ced4da;
      }
    }
    &--select {
      .form-control {
        &--height {
          &:focus {
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
            border: none;
          }
        }
      }
    }
  }

  .payment {
    .c-form {
      .havebg {
        padding: 20px;
        margin-bottom: 10px;
      }
      .text-right {
        display: flex;
        align-items: center;
        .form-control {
          margin-left: 10px;
          &:focus {
            box-shadow: none !important;
            border: 1px solid #ced4da;
          }
        }
      }
    }
  }

  .error {
    color: rgb(255, 153, 0);
  }
  .react-date-picker {
    display: inline-flex;
    position: relative;
    padding-right: 0 !important;
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-date-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none !important;
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-date-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    color: #495057 !important;
    -moz-appearance: textfield;
    &:focus-visible {
      border: none !important;
    }
  }
  .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-date-picker__inputGroup__input:invalid {
    background: transparent !important;
  }
  .react-date-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-date-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-date-picker__button:enabled {
    cursor: pointer;
  }
  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #0078d7;
  }
  .react-date-picker__button:disabled .react-date-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-date-picker__button svg {
    display: inherit;
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-date-picker__calendar--closed {
    display: none;
  }
  .react-date-picker__calendar .react-calendar {
    border-width: thin;
  }

  .react-calendar {
    width: 300px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: $font-family-other !important;
    line-height: 1em;
    font-size: 12px !important;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: transparent !important;
    color: inherit !important;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
}

.add-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  padding: 0 15px;
  margin-top: 10px;
  .btn-add-more {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: #07c3c3;
    }
  }
  .error {
    color: red;
  }
}

.remove-remember {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  padding: 0 15px;
  margin-top: 10px;
  color: #c36207;
  .btn-remove-remember {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: red;
    }
  }
}
.note-list {
  .note-item {
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
      text-align: left;
    }
    &:hover {
      color: #ff6903;
    }
  }
}
.btn-remove {
  cursor: pointer;
}
.modal-delivery {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px;
  padding: 10px;
  .delivery-option {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    &:focus {
      border-color: #ccc;
      box-shadow: none !important;
    }
  }
  ::-webkit-input-placeholder {
    color: #ccc;
  }
  :-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: #ccc;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #ccc;
  }
  ::-ms-input-placeholder {
    color: #ccc;
  }

  ::placeholder {
    color: #ccc;
  }
}

.modal-content {
  .havebg {
    padding: 20px;
    margin: 20px 0;
    background-color: #f2f0f0;
    border-radius: 10px;
    .custom-control-label {
      margin-bottom: 0 !important;
    }
  }
  .text-right {
    display: flex;
    align-items: center;
    .form-control {
      margin-left: 10px;
      &:focus {
        box-shadow: none !important;
        border: 1px solid #ced4da;
      }
    }
  }
}
