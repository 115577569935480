@import "../../../assets/css/base/functions";
@import "../../../assets/css/base/variables";
@import "../../../assets/css/base/mixins";

.create-group {
  .c-form .form-group label {
    font-size: $font-size-sm;
  }
  .form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }
  .form-group {
    .c-txt-blue {
      font-size: $font-size-sm;
    }
  }
  .box-setting {
    .custom-control-label {
      font-size: 0.75rem !important;
    }
  }
  #groupImageImg {
    z-index: 999;
  }

  .upload-image {
    width: 107px;
  }
}
.errorMessage {
  color: #ff9900;
}
.main-height {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;

  &.main-wellcome {
    .section-content .container {
      .box-center-welcome01 {
        .btn--main {
          margin-bottom: 3vh;
        }
      }
    }
  }

  .section-content {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .section-content {
    padding-bottom: 60px;
    padding-top: 100px;
    overflow: hidden;
    .container {
      padding-bottom: 20px;
      display: flex;
      height: 100%;
    }

    &--top {
      .container {
        position: relative;

        flex-wrap: wrap;

        > div {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .row-top {
          align-self: flex-start;
        }

        .row--bot,
        .row-bot {
          align-self: flex-end;

          margin-top: 10px;
        }
        .row-bot {
          &.is-fixed {
            position: fixed;
            bottom: 60px;
            background: #fff;
            left: 0;
            right: 0;
            max-width: 640px;
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            z-index: 99;
            border-top: 1px solid #f2f0f0;
            border-bottom: 1px solid #f2f0f0;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
      &.is-content-fixed {
        padding-bottom: 300px;
      }
    }

    &--welcome {
      position: relative;

      &:after {
        height: 50%;
        background-color: rgba($color: #868384, $alpha: 0.12);
        bottom: 20px;
        left: 0;
        right: 0;
        content: "";
        position: absolute;
      }

      > * {
        position: relative;
        z-index: 2;
      }
    }

    &--profile {
      padding-top: 92px;

      .c-form.c-form--profile {
        margin-top: -50px;
        margin-bottom: 20px;
      }
    }
  }
}
