.w-max-inherit {
  max-width: inherit;
}

.custom-scroll {
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
    width: 10px;
    background-color: #f5f5f5;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    background-color: #bcc0c4;
  }
}

.animate {
  transition: all 0.3s ease-in-out;
}

.menu-shrink {
  width: inherit;

  .wrap-calendar {
    display: none;
  }
}

.menu-dilate {
  width: 16.6666666667%;

  .wrap-calendar {
    display: flex;
    overflow: auto scroll;
    margin-right: 3px;
    height: calc(100% - 400px);
  }
}
