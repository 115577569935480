// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  width: 100%;
  border-radius: 30px;
  font-family: $font-family-other;
  font-size: rem(10);
  background-color: white;
  height: 37px;
  color: #079D9A;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  & ~ .btn{
    margin-top: 25px;
  }
  &:hover{
    background-color: #0BADAD;
    color: #fff;
  }
  &--gray{
    background-color: #868384;
    color: white;
  }
  &--graylight{
    background-color: rgba(149,149,149,0.7);
    color: white;
  }
  &--bold{
    font-weight: bold;
  }
  &--main{
    background-color: #07C3C3;
    color: white;
    text-transform: uppercase;
  }
  &--org{
    background:#FF6903;
    color: white;
    height: 25px;
    &.btn--org02{
      height: 37px;
    }
  }
  &--blue{
    background:#07C3C3;
    color: white;
    height: 25px;
    &.btn--org02{
      height: 37px;
    }
  }
  &--solid{
    background:#fff;
    color: #07C3C3;
    height: 25px;
    border:1px solid #07C3C3;
    &.btn--solid02{
      border:1px solid #868384;
      color: #868384;
      text-transform: uppercase;
      &:hover{
        color: #fff;
        border-color: #07C3C3;
      }
    }
    &.btn--solid03{
      height: 37px;
    }
    &.btn--solid04{
      height: 37px;
      color: #F06A0F;
      border:1px solid#F06A0F;
      background: none;
      &:hover{
        background-color:#F06A0F;
        color:white
      }
    }
    &.btn--solid05{
      background: none;
      text-transform: uppercase;
      font-size: rem(18);
      font-weight: bold;
      height: 80px;
      border-radius: 20px;
      border-color: #7C797A;
      color: #7C797A;
      flex-wrap: wrap;
      span{
        color: #07C3C3;
        display: block;
        flex: 0 0  100%;
        max-width: 100%;
      }
      b{
        font-size: rem(12);
        display: block;
        color: #7C797A;
        text-transform: initial;
      }
      &:hover{
        background-color:#F06A0F;
        color:white!important;
        span ,b{
        color:white!important;
        }
      }
    }
  }
  // @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

.btn-border{
  color: #F06A0F;
  font-size: rem(16);
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(28);
  background: #F2F0F0;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  &:hover{
    text-decoration: none;
    color: #fff;
    background: #F06A0F;
  }

}
// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
