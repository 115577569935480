@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

//Color Variables
$sidebar-background-color: #313443;
$active-sidebar-link-color: #22252e;
$hover-sidebar-link-color: $active-sidebar-link-color;
$active-link-color: #98d7ec;
$tooltip-background-color: $sidebar-background-color;

.sidebar {
  font-size: 14px;
  color: #1ca5a2;

  img {
    width: 2rem;
    height: 2rem;
  }

  a {
    color: #1ca5a2;
    &:hover {
      transition: all 0.2s ease-in-out;
    }
  }

  span:hover {
    opacity: 0.7;
  }

  .form-select {
    font-size: 0.75vw;
    padding: 0.375rem 1rem 0.375rem 0.5rem;
    background-position: right 0.25rem center;
  }

  .pl-15 {
    padding-left: 15px !important;
    transition: all 0.3s ease-in-out;
  }

  .list-menu {
    display: inline-block;

    .menu-item {
      cursor: pointer;
      transition: all ease-out 120ms;

      a {
        font-size: 0.85vw;
      }

      .icons {
        font-size: 18px;
        transition: all ease 450ms;
      }

      .tooltip {
        display: inline-block;
        position: absolute;
        background-color: #fd7e14;
        padding: 8px 15px;
        border-radius: 3px;
        margin-top: -3px;
        left: 65px;
        opacity: 0;
        visibility: hidden;
        font-size: 13px;
        width: max-content;
        letter-spacing: 0.5px;
        color: white !important;
        z-index: 999;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -4px;
          top: 12px;
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: inherit;
        }
      }

      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }

      &.active {
        background-color: black;
      }
    }
  }

  .style-menu {
    left: -3px;
    border-bottom: 25px solid transparent;
    border-right: 25px solid #f2f0f0;
    position: relative;
    cursor: pointer;
    z-index: 10;

    .group span {
      color: #707070;
    }

    span:nth-child(1) {
      position: absolute;
      top: 20px;
      right: -35px;
      display: inline-block;
      transform: rotate(-90deg);
    }

    span:nth-child(2) {
      position: absolute;
      top: 60px;
      font-size: 18px;
      left: 5px;
    }
  }

  .w-fit {
    width: fit-content !important;
  }
}

.text-orange {
  color: $orange !important;
}
