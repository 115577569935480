.profile-page {
  .c-form.c-form--profile {
    margin-top: -50px;
    margin-bottom: 20px;
  }

  .c-form .upload-image {
    width: 107px;
  }
}
.action-link {
  display: flex;
  padding: 0;
  list-style: none;
  width: 100%;
  justify-content: center;
  margin: 8px 0;
}
.logout-btn {
  border: 2px solid #f6660c;
  border-radius: 30px;
  margin: 12px 0;
  cursor: pointer;
  &:hover {
    background-color: #f6660c;
    li {
      color: white;
    }
  }
}
.action-link li {
  font-size: 14px;
  color: #f6660c;
  margin-right: 15px;
  font-weight: 600;
  cursor: pointer;
}
.switch-version {
  > p {
    text-align: center;
    color: #f6b20c;
    font-size: 0.6rem;
    margin-bottom: 6px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.upgrade-premium {
  display: flex;
  justify-content: center;
  width: 100%;
  > p {
    font-size: 0.8rem;
    color: #fd7e14;
    text-decoration: underline;
    cursor: pointer;
  }
}

.user-invoice {
  scroll-behavior: smooth;
  font-size: 0.725rem;
  .box-filter {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    .wrap-pagination {
      .btn-prev,
      .btn-next {
        background-color: #fff;
        padding: 8px 12px;
        border: 1px solid #ced4da;
        color: orange;
        font-weight: 500;
        width: auto;

        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: orange;
          color: #fff;
        }
      }
    }

    .select-filter {
      display: flex;
      select {
        font-size: 14px;
        outline: none;
        color: orange;
        border-color: gray;
      }
    }
  }
  .invoice-header {
    color: #15cdca;
    padding: 10px 0;
  }
  .invoice-item {
    padding: 10px 0px;
    border-top: 1px solid #dee2e6;
    &:last-child {
      border-bottom: 1px solid #dee2e6;
    }
    .cancel {
      cursor: pointer;
    }
    .payment {
      cursor: pointer;
    }
  }
}
.modal-cancel {
  .modal-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .modal-header {
      .modal-title {
        text-align: left !important;
      }
    }
  }
  .btn-no {
    border: 1px;
    outline: none;
    // border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 3px;
  }
  .btn-yes {
    outline: none;
    border: none;
    padding: 0.375rem 1rem;
    border-radius: 3px;
  }
}
